



import { TranslateResult } from 'vue-i18n';
import { Component, Vue } from 'vue-property-decorator';
import MessageCard from '@/components/MessageCard.vue';

@Component({
  components: {
    MessageCard,
  },
})
export default class NotFound extends Vue {
  msg: TranslateResult = this.$t('404.message');

  title: TranslateResult = this.$t('404.title');
}
